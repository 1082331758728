import { Environment } from './models/environment.models';
import { AvailableLanguageMutations } from './models/language.models';

export const environment: Environment = {
  production: true,
  apiUrl: 'https://mytitle.com',
  language: AvailableLanguageMutations.CZECH,
  version: '35ebee10088667aa5d35849c21cb575861a6b889',
  googleClientId: '670683061843-okcoeuaklei07icouss2t3guueeqva0t.apps.googleusercontent.com',
};
