 /* eslint-disable max-len */

export enum Notifications {
  Expires = 'Vyprší za ',
  UserNotRegistered = 'Uživatel není ještě registrován.',
  UserRegistered = 'Uživatel je již registrován.',
  NoLimit = 'Zdílení je časovo neomezené.',
  DocUpgradeSuccess = 'Úspěšně jste upgradovali váš dokument!',
  FolderUpgradeSuccess = 'Úspěšně jste upgradovali váši složku!',
  Unexpected = 'Neočekávaná chyba, která vznikla při nahrání souboru. Prosím zkuste znovu.',
  NotEnoughCredits = 'Nemáte dostatek kreditů',
  FolderUpgrade = 'Jste si jisti, že chcete vytvořit certifikát na tuto složku? Tato složka bude uzamknuta a nebudete mít možnost ji změnit.',
  Upgrade = 'Jste si jistý, že chcete upgradovat váš dokument?',
  MissingGuid = 'Chybí ID souboru',
  QuotaExceeded = 'Množství volných kreditů bylo překročeno',
  LinkSent = 'Potvrzovací link ke smazání byl poslán na váš email.',
  FileChanged = 'Váš soubor byl uspěšně změněn.',
  FileMoved = 'Váš soubor byl úspěšně přesunut.',
  FileRenamed = 'Váš soubor byl úspěšně přejmenován.',
  NotChecked = 'Neoznačil jste žádný dokument ke smazání.',
  DeleteSelected = 'Chcete opravdu smazat vybrané',
  Document = 'dokument',
  Documents = 'dokumenty',
  FolderMoved = 'Vaše složka byla úspěšně přesunuta.',
  FolderRenamed = 'Vaše složka byla úspěšně přejmenována.',
  LoggedOut = 'Byli jste odhlášeni',
  FileEmpty = 'Váš soubor je prázdný',
  MaxSize = 'Maximální velikost souboru je 500 MB',
  CopiedLink = 'Odkaz byl zkopírován',
  AlreadySigned = 'Už jste u nás zaregistrováni. Přihlásili jsme vás.',
  DisabledTwoFa = '2-fázové ověření bylo úspěšně odebráno.',
  DeletionStarted = 'Váš účet byl deaktivován a bude smazán za 30 dní.',
  AccountDeleted = 'Váš účet byl smazán.',
  ErrorOccured = 'Chyba nastala během ukládání změny na vašem účtě.',
  InfoChanged = 'Informace o účtu byly úspěšně změněny.',
  Invoice = 'Faktura nebyla stažena.',
  SharingWithYourself = 'Nemůžete sdílet dokument sám se sebou.',
  AlreadySharing = 'Tento dokument byl již sdílen s touto emailovou adresou.',
  Confirmed = 'Certifikát může být stažen jakmile je "orazítkován".',
  AuthError = 'Chyba nastala v ověření. Prosím zkuste se znovu přihlásit.',
  MailResent = 'Potvrzovací email byl zaslán',
  GoogleCodeIncorrect = 'Google ověřovací kód je nesprávný nebo mu vypršela platnost. Prosím zkuste znovu.',
  GoogleWindowClosed = 'Ověření nebylo úspěšné. Google ověřovací okno bylo zavřeno.',
  GoogleAccessDenied = 'Ověření nebylo úspěšné. Google zamítl přístup pro poskytnutý účet.',
  GoogleError = 'Chyba nastala během ověření pomocí Google účtu. Opakujte později.',
  WithoutName = 'Nahráno bez názvu',
  WrongButton = 'Namísto složky byl vybrán soubor. Použijte tlačítko "nahrát soubor".',
  NothingSelected = 'Žádné položky nebyly vybrány.',
  PaygateUnexpected = 'Neočekávaná chyba nastala během přístupu k platební bráně. Zkuste prosím znovu.',
  PaygateInvalidISIC = 'Pro zakoupení Academy kreditů zadejte číslo své ISIC karty.',
  PaygateISICBuyingStandard = 'Jste člen Academy. Vyberte si balíček z výhodnějších Academy kreditů.',
  LimitExceeded = 'Váš limit volných souborů byl překročen',
  FreeDocumentsInFolder = 'Ve složce, kterou chcete ověřit, jsou dokumenty zdarma. Nejprve ji upgradujte.',
  FolderLocked = 'Uzamčenou složku nelze měnit.',
  FolderEmpty = 'Nelze uzamknout prázdnou složku.',
  ContinueAfterUpload = 'V tomto procesu můžete pokračovat po nahrání souboru.',
  Remove = 'Klikněte pro odstranění údajů.',
  FileOrderError = 'Neočekávaná chyba nastala během zpracovávaní Vašeho dokumentu. Prosím zkuste znovu.',
}
